export const ENVIRONMENT = {
  nodeEnv: process.env.NODE_ENV,
  name: process.env.NEXT_PUBLIC_ENVIRONMENT || "",
  version: process.env.NEXT_PUBLIC_VERSION || "",
  domain: process.env.NEXT_PUBLIC_DOMAIN || "",
  messengerDomain: process.env.NEXT_PUBLIC_MESSENGER_DOMAIN || "",
  firebase: {
    region: process.env.NEXT_PUBLIC_FIREBASE_REGION || "",
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || "",
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId:
      process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || "",
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || "",
    isEmulator: process.env.NEXT_PUBLIC_FIREBASE_IS_EMULATOR === "true",
  },
};
